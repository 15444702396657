:root {
	--accentColor1: #f1f900;
	--accentColor2: #ff03ed;
	--accentColor3: #06ffe7;
	--errorColor: rgb(255, 0, 0);
	--sucsessColor: rgb(0, 255, 0);
	--twColor1: #1eabf1;
	--twColor2: #1c9bdb;
	--dsColor1: #5165ca;
	--dsColor2: #4458b8;
	--tgColor1: #1b9de2;
	--tgColor2: #1983bd;
	--rdColor1: #fd5830;
	--rdColor2: #c74221;
	--inColor1: #c63daa;
	--inColor2: #9c2083;
	--gridGap1: 10px;
	--gridGap2: 20px;
	--gridGap3: 30px;
}

@font-face {
	font-family: 'custom';
	src: url(./assets/fonts/custom.ttf) format('truetype');
	font-display: swap;
}

body {
	min-height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 0;
	background-color: #000000;
	/* background-image: url(./assets/images/bg.jpg); */
	/* background-attachment: fixed; */
	/* background-position: 0 0; */
	/* background-repeat: repeat; */
	color: #ffffff;
	font-family: "Helvetica Neue","Helvetica","Arial",  sans-serif;
  font-weight: 300;
}

#root {
	height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 0;
	display: grid;
	grid-template-rows: [row1-start] auto [row1-end] auto [third-line] 165px [last-line];
}

.body-overflow-js {
	overflow: hidden;
}

header, main #content, .footer-container {
	display: block;
	margin: 0;
	padding: 0;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

main {
  margin-bottom: 60px;
}

main, main #content {
	vertical-align: top;	
}

main #content a {
	color: var(--accentColor1);
	text-decoration: none;
	border-bottom: 1px solid var(--accentColor1);
}

main #content a:hover {
	text-decoration: none;
	border-bottom: none;
}

footer {
  border-bottom: 12px solid var(--accentColor1);
}

.footer-container {
	padding: 10px 10px 0 10px;
	/* border-top: 1px solid #fff; */
}

footer a, footer a:visited {
	color: #fff;
	margin: 0 5px;
	text-decoration: none;
}

footer a:hover {
	color: var(--accentColor1);
	text-decoration: underline;
}

#logo, .nav-menu, .header-buttons, .header-icons, .footer_links, .footer_social {
	display: block;
	width: 100%;
	text-align: center;
	margin: 10px auto;
}

.footer_links {
	margin: 0 auto;
}

.footer_social {
	margin: 5px auto;
}

.logo_footer {
	display: block;
	width: 160px;
	height: auto;
	text-align: center !important;
	margin-left: auto;
	margin-right: auto;
}

.footer_name {
	display: none;
	width: 100%;
	text-align: center;
	margin: 10px auto;
}

#logo img {
	width: 170px;
	height: auto;
	max-width: 100%;
	margin: 30px auto 10px;
}

.nav-menu a {
	display: inline-block;
	color: #ffffff;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.85em;
	font-weight: 500;
	padding: 4px 2px 5px;
	margin: 0 10px;
	border-bottom: 2px solid #000000;
}

.nav-menu a:hover {
	color: var(--accentColor1);
}

.menu-on-hover {
	color: var(--accentColor1) !important;
}

.header-buttons_buy, .header-buttons_wallet {
	display: inline-block;
	line-height: 100%;
  	background-color: var(--accentColor1);
  	border: none;
  	border-radius: 5px;
  	font-size: 13px;
  	color: black;
  	cursor: pointer;
  	margin: 0 5px;
	text-decoration: none;
	padding: .4em .5em;
	font-weight: bold;
	text-transform: uppercase;
}

.header-buttons_buy:hover, .header-buttons_wallet:hover {
	background-color: #ffffff;
	text-decoration: none;
}

.header-icons a {
	display: inline-block;
	width: 18px;
	height: 18px;
	padding:5px;
	margin: 0 2px;
}

.header-icons a svg  {
	display: block;
	width: 100%;
	height: auto;
	fill: #fff;
}

/* .header-icons a:hover svg {
	fill: var(--accentColor1);
} */

.header-email {
	display: none;
	color: #ffffff;
	font-size: 1.2em;
	font-weight: 300;
	text-decoration: none;
	vertical-align: top;
}

.footer-email {
	font-size: 1.4em !important;
	font-weight: 300 !important;
}

.header-email:hover {
	color: var(--accentColor1);
}

.hs-tw:hover svg{
	fill: var(--twColor1);
}

.hs-ds:hover svg{
	fill: var(--dsColor1);
}

.hs-tg:hover svg{
	fill: var(--tgColor1);
}

.hs-in:hover svg{
	fill: var(--inColor1);
}

.logo_footer {
	margin-top: -60px;
}

.logo_footer img {
	width: 160px;
	max-width: 100%;
	margin: 50px auto 60px;
}

.footer_links a {
	display: inline-block;
	color: #e6e6e6;
	font-size: 16px;
	font-weight: 400;
	margin: 0 7px 8px;
}

.footer_links a:hover {
	text-decoration: none;
}

.footer_social a {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.footer_social a, .footer_social a:hover {
	text-decoration: none;
	vertical-align: top;
	text-align: center;
}

.fs-tw svg, .fs-ds svg, .fs-tg svg, .fs-rd svg, .fs-in svg {
	fill: #ffffff;
	width: 16px;
	height: 16px;
}

.fs-tw svg {
	margin-top: 7px;
	margin-left: 2px;
}

.fs-ds svg {
	margin-top: 7px;
}

.fs-tg svg {
	margin-top: 7px;
	margin-left: -0.5px;
}

.fs-rd svg {
	margin-top: 7px;
	margin-left: -0.5px;
}

.fs-in svg {
	margin-top: 7px;
	margin-left: 0px;
}

.fs-tw {
	background-color: var(--twColor1);
}

.fs-tw:hover {
	background-color: var(--twColor2);
}

.fs-ds {
	background-color: var(--dsColor1);
}

.fs-ds:hover {
	background-color: var(--dsColor2);
}

.fs-tg {
	background-color: var(--tgColor1);
}

.fs-tg:hover {
	background-color: var(--tgColor2);
}

.fs-rd {
	background-color: var(--rdColor1);
}

.fs-rd:hover {
	background-color: var(--rdColor2);
}

.fs-in {
	background-color: var(--inColor1);
}

.fs-in:hover {
	background-color: var(--inColor2);
}

.page-text {
	padding: 0 20px;
	font-size: 1.2em;
  	font-weight: 300;
  	/* margin-bottom: 80px; */
}

.page-text h1 {
	line-height: 1.1;
}

.list-with-numb {
	margin-left: 5%;
}

.no-top-margin {
	margin-top: 0 !important;
}

.menu-selected {
	border-bottom: 2px solid var(--accentColor1) !important;
}

.center-content {
	text-align: center;
}

.mplayer-container {
    display: none;
    width: 48px;
    height: 24px;

    position: fixed;
    bottom: 30px;
    right: 25px;

    padding: 12px 0;

    background-color: var(--accentColor2);
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 0 10px var(--accentColor2);

    cursor: pointer;
}

.mplayer-container:hover {
    box-shadow: 0 0 15px var(--accentColor2);
}

.mplayer-container svg {
    width: 24px;
    height: 24px;
    fill: #ffffff;
    
}

.mplayer-play svg {
    margin-left: 5px;
}

.rutitle {
	font-family: "Helvetica Neue","Helvetica";
	text-transform: uppercase;
	letter-spacing: -2px;
	font-size: 1.6em;
	/* font-stretch: condensed; */
}

@media (min-width: 375px) {
	.header-icons {
	margin: 7px auto 3px auto;
	}
}

@media (min-width: 600px) {
	#logo, .nav-menu, .header-buttons, .header-icons, .footer_links, .footer_social {
		text-align: center;
		margin: 10px auto;
	}

	.rutitle {
		font-size: clamp(25px, 6vw, 80px);
	}
}

@media (min-width: 768px) {
	.header-icons a {
	display: inline-block;
	width: 16px;
	height: 16px;
	padding:3px 5px;
	}

	.mplayer-container {
        display: block;
    }
}

@media (min-width: 1100px) {
	#root {
    grid-template-rows: [row1-start] 75px [row1-end] auto [third-line] 170px [last-line];
	}
	
	header {
	margin: 3px auto;
	}

	/* main {
		margin-bottom: 80px;
	} */
	
	#logo, .nav-menu, .header-buttons, .header-icons {
	  display: inline-block;
	  vertical-align: middle;
	}
	
	#logo {
	  width: 180px;
	}

	#logo img {
	width: calc(100% - 20px);
	margin-left: 20px;
	margin-top: 15px;
	}
	
	.nav-menu {
	  width: 598px;
	  text-align: right;
	}
	
	.header-buttons {
	  width: 200px;
	  margin-top: -5px;
	}
	
	.header-icons {
	  width: 120px;
	  margin: 4px auto 3px auto;
	}

	.header-buttons_buy, .header-buttons_wallet {
	  margin: 0 5px;
	}
	
	.page-text {
	font-weight: 300;
	}

	.page-text-index p {
	margin: 12px 0;
	}

	/* .page-text { 
	  font-size: 1.3em;
	} */

	.page-text-top-margin {
		margin-top: 40px;
	}

	.page-text p {
		font-size: 1.4em;
	}

	.mplayer-container {
        bottom: 40px;
        right: 60px;
    }

	.header-email {
		display: inline-block;
	}

	.footer-email {
		font-size: 1.7em !important;
	}

	.rutitle {
		font-size: clamp(25px, 4vw, 80px);
	}

}

@media (min-width: 1300px) {
	header {
	max-width: 1300px;
	}

	.nav-menu {
	width: 620px;
	}
	  
	.header-buttons {
	width: 210px;	
	}

	#logo img {
	width: calc(100% - 20px);
	margin-left: 35px;
	margin-top: 20px;
	}

	.header-buttons {
		margin-top: 0;
	}
}

h2 {
	font-family: "custom";
	font-size: 5rem;
	font-size: clamp(35px, 7vw, 80px);
	line-height: 100%;
	margin-bottom: .5em;
	max-width: 80%;
}

h3 {
	font-family: custom;
	font-size: 2rem;
	line-height: 100%;
	margin-bottom: .5em;
	color: #e6e6e6;
}

